import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(
  ({shadow, breakpoints, palette, layout, spacing}) => ({
    title: {
      paddingTop: 80,
    },
    wrapper: {
      width: '100%',
      margin: 'auto',
      maxWidth: layout.worldMap.bigMaxSize.maxWidthLg,
      '& .leaflet-container': {
        height: layout.worldMap.heightMd,
        maxHeight: layout.worldMap.bigMaxSize.maxHeight,
        zIndex: 0,
        margin: 'auto',
        background: '#ffffff !important',
        [breakpoints.down(breakpoints.values.md)]: {
          height: layout.worldMap.heightSm,
        },
      },
      '& .leaflet-tile-container img': {
        width: layout.worldMap.fixGutterBetweenTiles,
        height: layout.worldMap.fixGutterBetweenTiles,
      },
      '& .leaflet-left': {
        bottom: 'inherit',
        top: 0,
        [breakpoints.down(breakpoints.values.md)]: {
          top: 'inherit',
          bottom: 0,
        },
      },
      '& .leaflet-marker-icon': {
        height: `${spacing(3)} !important`,
        width: `${spacing(3)} !important`,
        borderRadius: '50%',
        boxShadow: shadow.markerShadow,
      },
      '& .leaflet-marker-icon:hover': {
        height: `${spacing(4)} !important`,
        width: `${spacing(4)} !important`,
        marginLeft: `${spacing(-2.5)} !important`,
        marginTop: `${spacing(-2.5)} !important`,
        boxShadow: shadow.markerShadowHover,
      },
      '& .leaflet-marker-icon:focus': {
        height: `${spacing(4)} !important`,
        width: `${spacing(4)} !important`,
        marginLeft: `${spacing(-2.5)} !important`,
        marginTop: `${spacing(-2.5)} !important`,
        boxShadow: shadow.markerShadowHover,
        zIndex: '1000 !important',
      },
      '& .leaflet-popup': {
        marginBottom: spacing(5),
        width: spacing(40),
        [breakpoints.down('sm')]: {
          width: spacing(32),
        },
      },
      '& .leaflet-popup-content': {
        margin: 0,
        width: '100%!important',
        '& p': {margin: 0},
      },
      '& .leaflet-popup-content-wrapper': {
        borderRadius: '8px 35px !important',
        padding: 0,
      },
      '& .leaflet-popup-tip-container': {
        display: 'none',
      },
      '& .leaflet-popup-close-button': {
        top: `${spacing(2.5)} !important`,
        right: `${spacing(2.5)} !important`,
        color: `${palette.text.light}!important`,
        borderRadius: '50%',
        padding: '2px 0!important',
        [breakpoints.down('sm')]: {
          fontSize: '25px !important',
          width: 25,
          height: 25,
        },
      },
      '& .leaflet-tooltip': {
        padding: 0,
        borderRadius: `${spacing(1, 4.5)} !important`,
      },
      '& .leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar': {
        border: 'none',
      },
      '& .leaflet-touch .leaflet-bar a': {
        borderRadius: '50%',
        border: `1px solid ${palette.background.contrastGrey}`,
      },
      '& .leaflet-touch .leaflet-bar a:first-child': {
        borderRadius: '50%',
      },
      '& .leaflet-touch .leaflet-bar a:last-child': {
        borderRadius: '50%',
        marginTop: spacing(1),
      },
      '& .leaflet-top .leaflet-control': {
        [breakpoints.down('sm')]: {
          marginBottom: spacing(3),
        },
      },
      '& .leaflet-top .leaflet-control-zoom-in': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '& .leaflet-top .leaflet-control-zoom-out': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
  }),
)

export default useStyles
