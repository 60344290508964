import {useTheme} from '@mui/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

const useIsTablet = () => {
  const {breakpoints} = useTheme()

  return useMediaQuery(breakpoints.down('md'))
}

export default useIsTablet
