import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({fontSizes, palette, spacing}) => ({
  wrapper: {
    display: 'flex',
  },
  title: {
    fontSize: fontSizes.description,
    fontFamily: 'CamptonLight',
    margin: 'auto',
  },
  dot: {
    height: '30px',
    width: '30px',
    backgroundColor: palette.worldMap.coverage,
    borderRadius: '50%',
    display: 'inline-block',
    marginLeft: spacing(2),
  },
}))

export default useStyles
