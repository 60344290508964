import 'leaflet/dist/leaflet.css'
import * as R from 'ramda'
import {MapContainer, TileLayer} from 'react-leaflet'
import {shape} from 'prop-types'
import loadable from '@loadable/component'

import {useTheme} from '@mui/styles'
import React, {useEffect, useRef, useState} from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'

import {worldMapPropTypes} from 'helpers/propTypes'
import Title from 'components/UI/Title'
import useIsMobile from 'hooks/useIsMobile'
import useIsSmallMobile from 'hooks/useIsSmallMobile'
import useIsTablet from 'hooks/useIsTablet'

import Legend from './Legend'
import useStyles from './styles'

const WorldMap = ({
  markers,
  continents,
  phone,
  fax,
  popupButtonTitle,
  pins,
  hasNav,
  mapTitle,
  siteUrl,
  visitWebsite,
  countryNameForContactForm,
  legend,
}) => {
  const classes = useStyles()
  const mapIndexed = R.addIndex(R.map)
  const CardsList = loadable(() =>
    import('components/AboutUs/Locations/CardsList'),
  )
  const MapMarker = loadable(() => import('components/UI/MapMarker'))
  const NavButtons = loadable(() =>
    import('components/AboutUs/Locations/NavButtons'),
  )

  const [map, setMap] = useState(null)
  const [cardsToRender, setCardsToRender] = useState([])
  const [hash, setHash] = useState('')
  const [continent, setContinent] = useState(0)
  const [initialZoom, setInitialZoom] = useState(null)
  const defaultView = useIsMobile() ? [50, 0] : [17, 0]
  const defaultZoom = 0
  const americasView = useIsMobile() ? [14, -75] : [0, -180]
  const americasZoom = 0
  const africaView = [0, 41]
  const africaZoom = 0.7
  const asiaView = [10, 105]
  const asiaZoom = 1.5
  const europeView = useIsMobile() ? [50, 0] : [55, 30]
  const europeZoom = 1

  const continentsArray = [
    {slug: 'global', coord: defaultView, addZoom: defaultZoom},
    {slug: 'americas', coord: americasView, addZoom: americasZoom},
    {slug: 'africa', coord: africaView, addZoom: africaZoom},
    {slug: 'asia', coord: asiaView, addZoom: asiaZoom},
    {slug: 'europe', coord: europeView, addZoom: europeZoom},
  ]

  const reorderedContinents = continents && [
    {...continents[2]},
    {...continents[0]},
    {...continents[1]},
    {...continents[3]},
    {...continents[4]},
  ]

  const useXXSmallMobile = () => {
    const {breakpoints} = useTheme()

    return useMediaQuery(breakpoints.down(340))
  }
  const useXSmallMobile = () => {
    const {breakpoints} = useTheme()

    return useMediaQuery(breakpoints.down(430))
  }
  const useMediumMobile = () => {
    const {breakpoints} = useTheme()

    return useMediaQuery(breakpoints.down(800))
  }
  const useIsSmallTablet = () => {
    const {breakpoints} = useTheme()

    return useMediaQuery(breakpoints.down(1200))
  }

  const isTablet = useIsTablet()
  const isSmallTablet = useIsSmallTablet()
  const isMobile = useIsMobile()
  const isMediumMobile = useMediumMobile()
  const isSmallMobile = useIsSmallMobile()
  const isXSmallMobile = useXSmallMobile()
  const isXXSmallMobile = useXXSmallMobile()

  const applyZoom = zoomProps => {
    setInitialZoom(zoomProps)
    if (map !== null) {
      map.setZoom(zoomProps + continentsArray[continent].addZoom)
    }
  }

  useEffect(() => {
    if (isXXSmallMobile) {
      applyZoom(0.4)
    }
    if (isXSmallMobile && !isXXSmallMobile) {
      applyZoom(0.7)
    }
    if (isSmallMobile && !isXSmallMobile) {
      applyZoom(1.2)
    }
    if (isMediumMobile && !isSmallMobile) {
      applyZoom(1.6)
    }
    if (isMobile && !isMediumMobile) {
      applyZoom(1.9)
    }
    if (isSmallTablet && !isMobile) {
      applyZoom(2.3)
    }
    if (isTablet && !isSmallTablet) {
      applyZoom(2.5)
    }
    if (!isTablet) {
      applyZoom(2.6)
    }
  }, [
    map,
    isTablet,
    isSmallTablet,
    isMobile,
    isMediumMobile,
    isSmallMobile,
    isXSmallMobile,
    isXXSmallMobile,
  ])

  useEffect(() => {
    if (hasNav) {
      const hasHash = window.location.hash.substring(1)
      const validHash =
        hasHash &&
        continentsArray.filter(
          e => e.slug.toUpperCase() === hasHash.toUpperCase(),
        )
      const validIndice =
        hasHash &&
        continentsArray.findIndex(
          e => e.slug.toUpperCase() === hasHash.toUpperCase(),
        )

      if (validHash.length > 0) {
        setHash(validHash[0].slug)
        setContinent(validIndice)
      } else {
        window.location.hash = ''
        setHash('')
        setContinent(0)
      }
    } else {
      setContinent(0)
    }
  }, [typeof window !== 'undefined' && window.location.hash])

  const applyView = (positionProps, zoomProps) =>
    map.setView(positionProps, zoomProps)

  useEffect(() => {
    const cardsWithIndex =
      markers && markers.map((item, index) => ({index, ...item}))

    const filteredMarkers = hash
      ? cardsWithIndex.filter(
          e => e.continent.slug.toUpperCase() === hash.toUpperCase(),
        )
      : cardsWithIndex

    setCardsToRender(
      filteredMarkers && filteredMarkers.length > 0
        ? filteredMarkers
        : cardsWithIndex,
    )
    if (map !== null) {
      const filteredContinent = hash
        ? continentsArray.filter(
            e => e.slug.toUpperCase() === hash.toUpperCase(),
          )
        : [continentsArray[0]]

      applyView(
        filteredContinent[0].coord,
        initialZoom + filteredContinent[0].addZoom,
      )
    }
  }, [map, hash])

  useEffect(() => {}, [cardsToRender])

  const onNavButtonClick = (nameProp, indexProp) => {
    if (hasNav) {
      if (typeof window !== 'undefined') {
        // eslint-disable-next-line no-restricted-globals
        location.hash = nameProp
      }
      setHash(nameProp)
      setContinent(indexProp)
    }
  }

  const itemsRef = useRef([])

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, markers.length)
  }, [markers])

  const getTileLayerUrl = () => {
    const keyrusTileLayerId = 'ckoxaqnr30qm317o23ej2ha06'
    const klsTileLayerId = 'cko967qnu5s5d19pqpw3gycql'
    const currentTileLayerId = () => {
      if (
        process.env.GATSBY_WEBSITE === 'keyrus' ||
        process.env.GATSBY_WEBSITE === 'keyrus-management'
      ) {
        return keyrusTileLayerId
      }
      if (process.env.GATSBY_WEBSITE === 'kls') {
        return klsTileLayerId
      }

      return false
    }

    return `https://api.mapbox.com/styles/v1/renaudc/${currentTileLayerId()}/tiles/{z}/{x}/{y}@2x?access_token=${
      process.env.GATSBY_MAPBOX_ACCESS_TOKEN
    }`
  }

  const renderMap = (centerProp, zoomProp) => (
    <MapContainer
      center={centerProp}
      zoom={zoomProp}
      scrollWheelZoom={false}
      whenCreated={setMap}
      zoomSnap={0.1}
      minZoom={isMobile ? 2 : 3}
      maxZoom={4}
      attributionControl={false}
      autoPan={false}
      worldCopyJump
      maxBounds={[
        [-90, -180],
        [90, 180],
      ]}
      tap={false}
    >
      <TileLayer
        noWrap={false}
        tileSize={1024}
        zoomOffset={-2}
        reuseTiles
        url={getTileLayerUrl()}
      />
      {process.env.GATSBY_WEBSITE === 'kls' && (
        <Legend map={map} legend={legend} />
      )}
      {markers &&
        mapIndexed(
          (line, index) =>
            line.latitude &&
            line.longitude && (
              <MapMarker
                line={line}
                map={map}
                phoneText={phone}
                faxText={fax}
                popupButtonTitle={popupButtonTitle}
                key={`${line.id} - ${index}`}
                pins={pins}
                index={index}
                refProp={el => {
                  itemsRef.current[index] = el
                }}
                siteUrl={siteUrl}
                visitWebsite={visitWebsite}
                countryNameForContactForm={
                  countryNameForContactForm && countryNameForContactForm
                }
              />
            ),
          markers,
        )}
    </MapContainer>
  )

  useEffect(() => {
    document
      .querySelector('.leaflet-pane.leaflet-popup-pane')
      ?.addEventListener('click', event => {
        event.preventDefault()
      })
  }, [map])

  return (
    <div id="worldmap">
      {hasNav && (
        <div className="sideWrapper">
          <div className={classes.title}>
            <Title variant="h2" type="largeTitle" isCentered>
              {mapTitle}
            </Title>
          </div>
          {map && reorderedContinents && (
            <NavButtons
              continents={reorderedContinents}
              hash={hash}
              onNavButtonClick={(slug, index) => onNavButtonClick(slug, index)}
            />
          )}
        </div>
      )}
      <div className={classes.wrapper}>
        {renderMap(continentsArray[0].coord, initialZoom)}
      </div>
      {hasNav && (
        <div className={classes.cardsList}>
          {map && (
            <CardsList
              data={cardsToRender}
              applyView={(positionProps, zoomProps) =>
                applyView(positionProps, zoomProps)
              }
              itemsRef={itemsRef}
              phoneText={phone}
              faxText={fax}
            />
          )}
        </div>
      )}
    </div>
  )
}

WorldMap.propTypes = worldMapPropTypes.isRequired

WorldMap.defaultProps = shape({
  markers: null,
  continents: null,
  phone: '',
  fax: '',
  popupButtonTitle: '',
  pins: null,
  hasNav: false,
  legend: '',
})

export default WorldMap
