import {objectOf, string} from 'prop-types'
import {useEffect} from 'react'
import L from 'leaflet'

import useStyles from './styles'

function Legend({map, legend}) {
  const classes = useStyles()
  const legendTemplate = `
      <div class=${classes.wrapper}> 
        <h4 class=${classes.title}>${legend}</h4>
        <div class=${classes.dot} />
      </div>
    `

  useEffect(() => {
    if (map) {
      const mapLegend = L.control({position: 'topright'})

      mapLegend.onAdd = () => {
        const div = L.DomUtil.create('div', 'info legend')

        div.innerHTML = legendTemplate

        return div
      }

      mapLegend.addTo(map)
    }
  }, [map])

  return null
}

Legend.propTypes = {
  legend: string,
  map: objectOf({}),
}

Legend.defaultProps = {
  legend: '',
  map: null,
}

export default Legend
